import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  IonButton,
  useIonModal,
} from '@ionic/react';
import React, {useContext} from 'react';

import NotificationsModal from './NotificationsModal';
import {AppContext, getNotifications} from '../State';

const NotificationsButton: React.FC = () => {
  const {state} = useContext(AppContext);

  const notifications: any[] = getNotifications(state);

  const handleDismiss = () => {
    dismiss();
  }

  const [present, dismiss] = useIonModal(NotificationsModal, {
    notifications,
    onDismiss: handleDismiss,
  });

  return (
    <div>
      <IonButton color="primary" onClick={() => present()}>
        <FontAwesomeIcon className={notifications.length ? 'color-danger' : ''} icon={['fad', 'bell']} size="lg"/>
      </IonButton>
    </div>
  );
};

export default NotificationsButton;
