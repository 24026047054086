import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {useContext} from 'react';

import AnnouncementCard from "../components/AnnouncementCard";
import NotificationsButton from '../components/NotificationsButton';
import {AppContext, getAnnouncements} from '../State';

const Announcements: React.FC = () => {
  const {state} = useContext(AppContext);

  const announcements = getAnnouncements(state);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
          <IonTitle>Announcements</IonTitle>
          <IonButtons slot="end">
            <NotificationsButton/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {announcements.length ? (
        <IonContent className="ion-padding" fullscreen>
          {announcements.map((announcement: any, index: any) => (
            <div key={index}>
              <AnnouncementCard announcement={announcement}/>
            </div>
          ))}
        </IonContent>
      ) : (
        <IonContent className="ion-padding">
          No announcements
        </IonContent>
      )}
    </IonPage>
  );
};

export default Announcements;
