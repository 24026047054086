import {useContext} from 'react';

import AnnouncementModal from './AnnouncementModal';
import {AppContext, getAnnouncements} from '../State';

const AnnouncementModals: React.FC = () => {
  const {state} = useContext(AppContext);

  let announcements = getAnnouncements(state);

  if (!announcements) {
    return null;
  }

  announcements = announcements.filter((a: any) => a.needsConfirmation && !a.confirmedAt);

  return (
    <div>
      {announcements.map((announcement: any, index: any) => (
        <div key={index}>
          <AnnouncementModal announcement={announcement}/>
        </div>
      ))}
    </div>
  );
};

export default AnnouncementModals;
