export const sitecontrollerGlobals = {
  environments: {
    'prod': {
      url: 'https://portal.sitecontroller.net',
      refreshRate: 10,
    },
    'dev': {
      url: 'http://symfony-sitecontroller',
      refreshRate: 30,
    },
  },
};
