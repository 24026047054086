import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  useIonAlert,
} from '@ionic/react';
import {useContext, useState} from 'react';

import { AppContext, getAuthToken, getEnvironment } from '../State';
import {sitecontrollerGlobals} from '../variables';

const ChangePasswordForm: React.FC = () => {
  const {state} = useContext(AppContext);

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [handlingForm, setHandlingForm] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  const authToken = getAuthToken(state);
  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

  const [present] = useIonAlert();

  const handleSubmit = () => {
    setHandlingForm(true);

    fetch(`${environmentOptions.url}/api/changePassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify({password: password, currentPassword: currentPassword}),
    })
      .then(response => response.json())
      .then(response => {
        if (!response.message) {
          setPassword('');
          setPasswordConfirmation('');
          setCurrentPassword('');
          setFormSuccess(true);
          setHandlingForm(false);

          return;
        }

        present({
          header: 'Failed to save password',
          message: response.message,
          buttons: [
            {text: 'Dismiss'},
          ],
        });

        setHandlingForm(false);
      })
      .catch(() => {
        setHandlingForm(false);
      })
    ;
  };

  const buttonDisabled = '' === password || '' === passwordConfirmation ||  '' === currentPassword || password !== passwordConfirmation || handlingForm;
  const confirmationError = '' !== passwordConfirmation && password !== passwordConfirmation;

  return (
    <div>
      {formSuccess ? (
        <IonItem>
          <IonLabel><IonNote className="color-success">Password saved</IonNote></IonLabel>
        </IonItem>
      ) : null}
      <IonItem>
        <IonLabel position="floating">New password</IonLabel>
        <IonInput
          type="password"
          value={password}
          onIonChange={(e) => setPassword(e.detail.value!)}
          onIonInput={() => setFormSuccess(false)}
        >
        </IonInput>
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Repeat new password</IonLabel>
        <IonInput
          type="password"
          value={passwordConfirmation}
          onIonChange={(e) => setPasswordConfirmation(e.detail.value!)}
          onIonInput={() => setFormSuccess(false)}
        />
      </IonItem>
      {confirmationError ? (
        <IonItem>
          <IonLabel><IonNote className="color-danger">Passwords do not match</IonNote></IonLabel>
        </IonItem>
      ) : null}
      <IonItem style={{marginTop: '2em'}}>
        <IonLabel position="floating">Current password</IonLabel>
        <IonInput
          type="password"
          value={currentPassword}
          onIonChange={(e) => setCurrentPassword(e.detail.value!)}
          onIonInput={() => setFormSuccess(false)}
        />
      </IonItem>
      <IonButton expand="block" onClick={handleSubmit} disabled={buttonDisabled} style={{marginTop: '1em'}}>Save</IonButton>
    </div>
  );
};

export default ChangePasswordForm;
