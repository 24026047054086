import {Preferences} from '@capacitor/preferences';

export const fillStorage = async state => {
  await Preferences.set({
    key: 'state',
    value: JSON.stringify(state),
  });
};

export const retrieveStorage = async () => {
  const {value} = await Preferences.get({key: 'state'});

  return JSON.parse(value);
};

export const clearStorage = async () => {
  await Preferences.remove({key: 'state'});
};
