class Autofetcher {
  intervalId: any;
  fetchers: any = {};
  refreshRate: number = 30;

  start() {
    this.intervalId = setInterval(() => {
      this.fetch();
    }, this.refreshRate * 1000);
  }

  stop() {
    clearInterval(this.intervalId);
  }

  setRefreshRate(refreshRate: number) {
    this.refreshRate = refreshRate;

    this.stop();
    this.start();
  }

  addFetcher(name: string, fetcher: any) {
    this.fetchers[name] = fetcher;

    // console.log('added fetcher: ' + name);
  }

  removeFetcher(name: string) {
    delete this.fetchers[name];

    // console.log('removed fetcher: ' + name);
  }

  fetch() {
    const promises = Object.values(this.fetchers).map((fetcher: any) => fetcher());

    return Promise.all(promises);
  }
}

const autoFetcher = new Autofetcher();

export default autoFetcher;
