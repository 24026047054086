import React from 'react';

export const AppContext = React.createContext();

const reducer = (state, action) => {
  // user = getUser(state);

  switch (action.type) {
    case 'SET_STATE': {
      return {
        ...action.state,
      };
    }

    case 'LOGOUT': {
      return {
        ...state,
        auth: {
          ...state.auth,
          token: null,
          user: null,
        },
      };
    }

    case 'LOGGED_IN': {
      return {
        ...state,
        auth: {
          ...state.auth,
          token: action.token,
          user: action.user,
          environment: action.environment,
        },
      };
    }

    case 'UPDATE_USER': {
      return {
        ...state,
        auth: {
          ...state.auth,
          user: action.user,
        },
      };
    }

    case 'SET_ANNOUNCEMENTS': {
      return {
        ...state,
        announcements: action.announcements,
      };
    }

    case 'SET_NOTIFICATIONS': {
      return {
        ...state,
        notifications: action.notifications,
      };
    }

    case 'SET_SITES': {
      return {
        ...state,
        sites: action.sites,
      };
    }

    default: {
      return state;
    }
  }
};

const logger = (reducer) => {
  const reducerWithLogger = (state, action) => {
    console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
    console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
    console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducer(state,action));
    return reducer(state,action);
  };

  return reducerWithLogger;
}

const loggerReducer = logger(reducer);

const initialState = {
  announcements: [],
  auth: {
    token: null,
    user: null,
    environment: 'prod',
  },
  notifications: [],
  sites: [],
};

export function AppContextProvider(props) {
  const fullInitialState = {
    ...initialState,
  }

  let [state, dispatch] = React.useReducer(reducer, fullInitialState);
  let value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  );
}

export const AppContextConsumer = AppContext.Consumer;

export const logout = () => ({
  type: 'LOGOUT',
});

export const loggedIn = (token, user, environment) => ({
  type: 'LOGGED_IN',
  token,
  user,
  environment,
});

export const updateUser = (user) => ({
  type: 'UPDATE_USER',
  user,
});

export const setAnnouncements = (announcements) => ({
  type: 'SET_ANNOUNCEMENTS',
  announcements,
});

export const setNotifications = (notifications) => ({
  type: 'SET_NOTIFICATIONS',
  notifications,
});

export const setSites = (sites) => ({
  type: 'SET_SITES',
  sites,
});

export const setState = (state) => ({
  type: 'SET_STATE',
  state,
});

export const getAuthToken = (state) => state.auth.token;
export const getEnvironment = (state) => state.auth.environment ?? 'prod';
export const getUser = (state) => state.auth.user;

export const getAnnouncements = (state) => state.announcements;
export const getNotifications = (state) => state.notifications;

export const getSites = (state) => state.sites;
