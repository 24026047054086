import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {useContext, useRef, useState} from 'react';

import {AppContext, getAuthToken, getEnvironment} from '../State';
import {sitecontrollerGlobals} from '../variables';

const AnnouncementCard: React.FC<{announcement: any}> = (props: any) => {
  const {state} = useContext(AppContext);

  const {announcement} = props;

  const authToken = getAuthToken(state);
  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

  const modal = useRef<HTMLIonModalElement>(null);
  const [closed, setClosed] = useState(false);

  const confirm = () => {
    setClosed(true);

    fetch(`${environmentOptions.url}/api/announcements/${announcement.id}/confirm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
    });
  };

  return (
    <div>
      <IonModal ref={modal} trigger="open-modal" isOpen={!closed} onDidDismiss={() => setClosed(true)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => modal.current?.dismiss()}>Dismiss</IonButton>
            </IonButtons>
            <IonTitle>Announcement</IonTitle>
            <IonButtons slot="end">
              <IonButton strong={true} onClick={() => confirm()}>
                Confirm
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <h1>{announcement.title}</h1>
          <div dangerouslySetInnerHTML={{__html: announcement.body}}></div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default AnnouncementCard;
