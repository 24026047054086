import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';
import {useContext} from 'react';

import {AppContext, getAuthToken, getEnvironment} from '../State';
import {sitecontrollerGlobals} from '../variables';

const AnnouncementCard: React.FC<{announcement: any}> = (props: any) => {
  const {state} = useContext(AppContext);

  const {announcement} = props;

  const authToken = getAuthToken(state);
  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

  const confirm = () => {
    fetch(`${environmentOptions.url}/api/announcements/${announcement.id}/confirm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
    });
  };

  return (
    <div>
      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>{announcement.visibleFrom}</IonCardSubtitle>
          <IonCardTitle>{announcement.title}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <div dangerouslySetInnerHTML={{__html: announcement.body}}></div>
          {announcement.needsConfirmation ? (
            <div>
              {announcement.confirmedAt ? (
                <div className="ion-margin-top">
                  Confirmed at {announcement.confirmedAt}
                </div>
              ) : (
                <div className="ion-margin-top">
                  <IonButton size="small" onClick={confirm}>Confirm</IonButton>
                </div>
              )}
            </div>
          ) : null}
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default AnnouncementCard;
