import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonRouterLink,
} from '@ionic/react';
import { useContext } from 'react';

import './SiteCard.css';
import { sitecontrollerGlobals } from '../variables';
import { AppContext, getEnvironment } from '../State';

const SiteCard: React.FC<{site: any}> = (props: any) => {
  const {state} = useContext(AppContext);

  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

  const {site} = props;

  return (
    <div className="site-card-container">
      <IonRouterLink routerLink={`/site/${site.id}`}>
        <IonCard className={site.status ? 'danger-card' : ''}>
          <IonCardHeader>
            <IonCardTitle>
              <strong>{site.name}</strong>
              {site.status ? <FontAwesomeIcon className="color-danger" icon={['fas', 'exclamation-triangle']} style={{marginTop: '.2em'}}/> : null}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent style={{alignItems: 'flex-end'}}>
            {site.image ? (
              <img className="site-image" src={`${environmentOptions.url}/media/uploads/images/${site.image}`} alt={site.name}/>
            ) : (
              <div className="color-muted" style={{display: 'flex', alignItems: 'flex-end', height: 50}}>
                <FontAwesomeIcon icon={['fal', 'map-marker-alt']} size="2x"/>
              </div>
            )}
            <div>{site.lastUpdatedAt}</div>
          </IonCardContent>
        </IonCard>
      </IonRouterLink>
    </div>
  );
};

export default SiteCard;
