import {SocialSharing} from '@awesome-cordova-plugins/social-sharing';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {Fancybox as NativeFancybox} from '@fancyapps/ui/dist/fancybox.esm';
import {IonButton, IonCol, IonRow, isPlatform} from '@ionic/react';
import {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';

import {AppContext, getAuthToken, getEnvironment} from '../State';
import {sitecontrollerGlobals} from '../variables';

const placeholder = '/assets/nocamera.png';
let collectedSnapshots: any = {};
let collectedSnapshotDownloads: any = {};

async function base64FromPath(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject('method did not return a string');
      }
    };
    reader.readAsDataURL(blob);
  });
}

const Snapshots: React.FC<{more?: boolean, name: string, snapshots: any}> = (props: any) => {
  const {state} = useContext(AppContext);

  const [, setSnapshotState] = useState(0);
  const [page, setPage] = useState(0);
  const [loadedSnapshots, setLoadedSnapshots] = useState<any[]>([]);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const pageSize = 4;

  const authToken = getAuthToken(state);
  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

  const {name, snapshots} = props;

  const history = useHistory();

  const loadNextPage = () => {
    if (!snapshots) return;

    const newLoaded: any[] = [...loadedSnapshots];

    for (let i = page * pageSize; i < (pageSize * (page + 1)); i++) {
      if (typeof snapshots[i] === 'undefined') {
        setCanLoadMore(false);

        break;
      }

      const snapshot = snapshots[i];

      if (typeof collectedSnapshots[snapshot.url] === 'undefined') {
        collectedSnapshots[snapshot.url] = false;

        fetch(environmentOptions.url + snapshot.url, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        })
          .then(response => response.blob())
          .then(async response => {
            collectedSnapshots[snapshot.url] = URL.createObjectURL(response);
            collectedSnapshotDownloads[snapshot.url] = await base64FromPath(response);

            setSnapshotState(new Date().getTime());
          });
      }

      newLoaded.push(snapshot);
    }

    setLoadedSnapshots(newLoaded);

    const nextPage = page + 1;
    setPage(nextPage);

    if (typeof snapshots[(nextPage * pageSize) + 1] !== 'undefined') {
      setCanLoadMore(true);
    }
  }

  useEffect(() => {
    if (!snapshots) return;

    loadNextPage();

    NativeFancybox.bind(`[data-fancybox="${name}"]`, {
      on: {
        ready: (fancybox: any) => {
          const downloadButtons = fancybox.$carousel.querySelectorAll('[data-snapshot-image]');

          downloadButtons.forEach((downloadButton: any) => {
            downloadButton.addEventListener('click', async (event: Event) => {
              event.preventDefault();

              const fileData = await Filesystem.writeFile({
                path: downloadButton.dataset.snapshotFilename,
                data: collectedSnapshotDownloads[downloadButton.dataset.snapshotImage],
                directory: Directory.Cache,
              });

              await SocialSharing.share(undefined, downloadButton.dataset.snapshotName, fileData.uri);
            });
          });
        },
        destroy: () => {
          history.goBack();
        },
      },
    });

    return () => {
      NativeFancybox.unbind(`[data-fancybox="${name}"]`);
    };
  }, []);

  const renderSnapshot = (snapshot: any) => {
    const url = typeof collectedSnapshots[snapshot.url] === 'string' ? collectedSnapshots[snapshot.url] : null;

    let caption = `<a class="download-button" href="${url}" download="${snapshot.title}.jpeg">Download</a>`;
    if (isPlatform('capacitor')) {
      caption = `<div class="download-button" data-snapshot-name="${snapshot.date}" data-snapshot-filename="${snapshot.title}.jpeg" data-snapshot-image="${snapshot.url}">Download</div>`;
    }

    return (
      <a href={url ?? placeholder} className="color-text no-link" data-fancybox={name} data-caption={caption}>
        <img className="rounded" src={url ?? placeholder} alt="Snapshot"/>
        <small>{snapshot.date}</small>
      </a>
    );
  };

  return (
    <div>
      <IonRow>
        {snapshots ? loadedSnapshots.map((snapshot: any, index: number) => (
          <IonCol key={index} sizeXs="6" sizeMd="3" sizeXl="6">{renderSnapshot(snapshot)}</IonCol>
        )) : (
          <IonCol sizeXs="6" sizeMd="3" sizeXl="6"><img src={placeholder} alt="Camera placeholder"/></IonCol>
        )}
      </IonRow>
      {canLoadMore ? (
        <IonButton size="small" onClick={loadNextPage}>Load more</IonButton>
      ) : null}
    </div>
  );
};

export default Snapshots;
