import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonToolbar,
  useIonAlert
} from '@ionic/react';
import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';

import NotificationsButton from '../components/NotificationsButton';
import Snapshots from '../components/Snapshots';
import autoFetcher from '../services/Autofetcher';
import { AppContext, getAuthToken, getEnvironment, getUser } from '../State';
import {sitecontrollerGlobals} from '../variables';

const Sensor: React.FC = () => {
  const {state} = useContext(AppContext);
  const {sensorId, siteId} = useParams<{sensorId: string, siteId: string}>();
  const user: any = getUser(state);

  const [sensor, setSensor] = useState<any>(null);

  const authToken = getAuthToken(state);
  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

  const [present] = useIonAlert();

  useEffect(() => {
    let isMounted = true;

    setSensor(null);

    const fetchSensor = () => {
      return fetch(`${environmentOptions.url}/api/sensor/${siteId}/${sensorId}?expanded=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      })
        .then(response => response.json())
        .then(response => {
          if (!isMounted) {
            return;
          }

          setSensor(response);
        });
    };

    fetchSensor();

    autoFetcher.addFetcher('sensor', fetchSensor);

    return () => {
      isMounted = false;

      autoFetcher.removeFetcher('sensor');
    };
  }, [authToken, sensorId, siteId]);

  const executePayloadAction = (action: any) => {
    const executor = () => {
      fetch(`${environmentOptions.url}/api/action/${sensorId}/${action.key}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      });
    };

    present({
      header: action.label,
      message: 'Are you sure?',
      buttons: [
        {text: 'Cancel'},
        {text: 'Execute', handler: () => executor()},
      ],
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/site/${siteId}`} text={sensor ? sensor.site.name : null}/>
          </IonButtons>
          <IonButtons slot="end">
            <NotificationsButton/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {null !== sensor ? (
        <IonContent className="ion-padding" fullscreen>
          <IonRefresher
            slot="fixed"
            onIonRefresh={event => autoFetcher.fetch().then(() => event.detail.complete())}
            pullMin={100}
            pullMax={100}
          >
            <IonRefresherContent refreshingSpinner="crescent"/>
          </IonRefresher>
          <div className="column-view-container">
            <div className="column-view-main-column">
              <div className="ion-hide-xl-up">
                <div className="ion-text-center"><img src={`${environmentOptions.url}/build/icons/${sensor.icon}`} style={{height: '6em', marginTop: '6em'}}/></div>

                <h4 className="ion-text-center" style={{marginBottom: '3em'}}>
                  <strong>{sensor.name}</strong>
                  <IonBadge color={sensor.statusColor[0]} style={{marginLeft: '1em'}}>{sensor.statusAbbr}</IonBadge>
                </h4>
                {sensor.description ? <div>{sensor.description}</div> : null}
              </div>
              <div className="ion-hide-xl-down">
                <h3 style={{marginBottom: '3em'}}>
                  <strong>{sensor.name}</strong>
                  <IonBadge color={sensor.statusColor[0]} style={{marginLeft: '1em'}}>{sensor.statusAbbr}</IonBadge>
                </h3>
              </div>

              <div style={{marginBottom: '2em'}}>
                <h5>Status</h5>
                <div className={`color-${sensor.statusColor[0]}`}>{sensor.statusText}</div>
              </div>

              {sensor.payloadActions.length > 0 ? (
                <IonGrid style={{padding: 0, marginBottom: '2em'}}>
                  <IonRow>
                    {sensor.payloadActions.map((action: any, index: string) => (
                      <IonCol key={index} sizeXs="6" sizeSm="4" sizeMd="3" sizeXl="4" style={{padding: 0}}>
                        <IonCard style={{margin: 0}}>
                          <IonCardHeader>
                            <IonCardTitle>{action.label}</IonCardTitle>
                          </IonCardHeader>
                          <IonCardContent>
                            {/*<p>Explanation</p>*/}
                            <IonButton size="small" color="light" disabled={action.disabled} onClick={() => executePayloadAction(action)}>{action.label}</IonButton>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    ))}
                  </IonRow>
                </IonGrid>
              ) : null}

              <IonGrid style={{marginBottom: '2em', padding: 0}}>
                <IonRow>
                  {Object.values(sensor.payloadData).map((payload: any, key: number) => (
                    <IonCol key={key} sizeXs="6" style={{padding: 0}}>
                      <h6 className="ion-text-bold">{payload.label}</h6>
                      <div>{payload.textValue ?? payload.value}</div>
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>

              {typeof sensor.snapshots !== 'undefined' ? (
                <div>
                  <h5>Snapshots</h5>
                  <Snapshots name={`sensor-${sensor.id}`} snapshots={sensor.snapshots}/>
                </div>
              ): null}

              {user.settings.logsAccess ? (
                <div>
                  <h5>Notifications</h5>
                  <IonList>
                    {sensor.notifications.length > 0 ? sensor.notifications.map((notification: any, key: number) => (
                      <IonItem key={key}>
                        <IonLabel>
                          <h3 className={notification.active ? 'color-danger' : ''}>{notification.message}</h3>
                          <p>{notification.createdAt}</p>
                        </IonLabel>
                      </IonItem>
                    )) : (
                      <IonItem>
                        <IonLabel className="ion-text-center">No notifications</IonLabel>
                      </IonItem>
                    )}
                  </IonList>

                  {sensor.payloadActions.length ? (
                    <>
                      <h5>Action logs</h5>
                      <IonList>
                        {sensor.actionLogs.length > 0 ? sensor.actionLogs.map((log: any, key: number) => (
                          <IonItem key={key}>
                            <IonLabel>
                              <p className="color-text">{log.action}</p>
                              <p>{log.user} - {log.performedAt}</p>
                            </IonLabel>
                          </IonItem>
                        )) : (
                          <IonItem>
                            <IonLabel className="ion-text-center">No action logs</IonLabel>
                          </IonItem>
                        )}
                      </IonList>
                    </>
                  ) : null}

                  <h5>Logs</h5>
                  <IonList>
                    {sensor.logs.length > 0 ? sensor.logs.map((log: any, key: number) => (
                      <IonItem key={key}>
                        <IonLabel>
                          <p className="color-text">{log.message}</p>
                          <p>{log.loggedAt}</p>
                        </IonLabel>
                      </IonItem>
                    )) : (
                      <IonItem>
                        <IonLabel className="ion-text-center">No logs</IonLabel>
                      </IonItem>
                    )}
                  </IonList>
                </div>
              ) : null}
            </div>
            <div className="column-view-side-column">
              <div className="ion-text-center" style={{marginTop: '6em'}}><img src={`${environmentOptions.url}/build/icons/${sensor.icon}`} style={{height: '9em'}}/></div>
            </div>
          </div>
        </IonContent>
      ) : (
        <IonContent>
          <div className="loader">
            <FontAwesomeIcon className="color-primary" icon={['fad', 'spinner-third']} size="3x" spin/>
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};

export default Sensor;
