import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonToolbar
} from '@ionic/react';
import React from 'react';

const NotificationsModal: React.FC<{notifications: any, onDismiss: any}> = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
          </IonButtons>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={() => props.onDismiss()}>
              <FontAwesomeIcon icon={['fas', 'times']}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonList>
          {props.notifications.length > 0 ? props.notifications.map((notification: any, key: number) => (
            <IonItem key={key} routerLink={notification.url}>
              <IonLabel>
                <h3 className={notification.active ? 'color-danger' : ''}>{notification.message}</h3>
                <p>{notification.createdAt}</p>
              </IonLabel>
            </IonItem>
          )) : (
            <IonItem>
              <IonLabel>
                <h3 className="color-muted ion-text-center">No active notifications</h3>
              </IonLabel>
            </IonItem>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default NotificationsModal;
