import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';

import NotificationsButton from '../components/NotificationsButton';
import SensorCard from '../components/SensorCard';
import Snapshots from '../components/Snapshots';
import autoFetcher from '../services/Autofetcher';
import {AppContext, getAuthToken, getEnvironment} from '../State';
import {sitecontrollerGlobals} from '../variables';

const Site: React.FC = () => {
  const {state} = useContext(AppContext);
  const {siteId} = useParams<{siteId: string}>();

  const [site, setSite] = useState<any>(null);
  const [activeCategory, setActiveCategory] = useState<any>(null);
  const [showNotices, setShowNotices] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const authToken = getAuthToken(state);
  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

  const categories = site ? [...(new Set(site.sensors.map((sensor: any) => sensor.category)) as any)].filter(s => s !== null) : [];
  categories.sort((a, b) => a.localeCompare(b));

  let sensors = site ? site.sensors : [];
  const noticeSensorCount = sensors.filter((sensor: any) => sensor.status && sensor.propagateStatus).length;

  if (showNotices) {
    sensors = sensors.filter((sensor: any) => sensor.status && sensor.propagateStatus);
  } else if (null !== activeCategory) {
    sensors = sensors.filter((sensor: any) => activeCategory === sensor.category);
  }

  useEffect(() => {
    let isMounted = true;

    setSite(null);

    const fetchSite = () => {
      return fetch(`${environmentOptions.url}/api/site/${siteId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      })
        .then(response => response.json())
        .then(response => {
          if (!isMounted) {
            return;
          }

          setSite(response);
        });
    };

    fetchSite();

    autoFetcher.addFetcher('site', fetchSite);

    return () => {
      isMounted = false;

      autoFetcher.removeFetcher('site');
    };
  }, [authToken, siteId]);

  useEffect(() => {
    if (showNotices && noticeSensorCount === 0) {
      setShowNotices(false);
    }
  }, [noticeSensorCount, showNotices, site]);

  const handleCategoryToggle = (category: string|null) => {
    setActiveCategory(activeCategory === category ? null : category);
    setShowNotices(false);
  };

  const handleNoticesToggle = () => {
    setShowNotices(!showNotices);
    setActiveCategory(null);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"/>
          </IonButtons>
          <IonButtons slot="end">
            <NotificationsButton/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {null !== site ? (
        <IonContent fullscreen>
          <IonRefresher
            slot="fixed"
            onIonRefresh={event => autoFetcher.fetch().then(() => event.detail.complete())}
            pullMin={100}
            pullMax={100}
          >
            <IonRefresherContent refreshingSpinner="crescent"/>
          </IonRefresher>
          <div className={!showSidebar ? 'sidebar-grid-container' : 'sidebar-grid-container sidebar-toggled'}>
            <div className="sidebar-grid-main-column ion-padding">
              <div className="sticky-header">
                <h3 className="flex-title">
                  <FontAwesomeIcon icon={['fal', 'map-marker-alt']} style={{marginLeft: '.8em', marginRight: '.8em'}}/>
                  <div className="flex-grow">
                    {site.name}
                    {site.status ? <FontAwesomeIcon className="color-danger" icon={['fad', 'exclamation-triangle']} style={{marginTop: '.2em', marginLeft: '1.5em'}}/> : null}
                  </div>
                  <div className="ion-hide-xl-up" role="button" onClick={toggleSidebar} style={{paddingLeft: '.8em', paddingRight: '.8em'}}>
                    <FontAwesomeIcon icon={['fas', 'ellipsis-v']}/>
                  </div>
                </h3>
                <div className="horizontal-scroll-container">
                  <IonButton color={null === activeCategory && !showNotices ? 'primary' : 'light'} onClick={() => handleCategoryToggle(null)}>All</IonButton>
                  {noticeSensorCount > 0 ? <IonButton color={showNotices ? 'primary' : 'light'} onClick={handleNoticesToggle}>Notices</IonButton> : null}
                  {categories.map((category, index) => null !== category ? <IonButton key={index} color={activeCategory === category ? 'primary' : 'light'} onClick={() => handleCategoryToggle(category)}>{category}</IonButton> : null)}
                </div>
              </div>
              <IonGrid>
                <IonRow className="sensor-card-row">
                  {sensors.map((sensor: any, index: string) => (
                    <IonCol key={index} size="12" sizeSm="6" sizeMd="4" sizeXl="4">
                      <SensorCard site={site} sensor={sensor}/>
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            </div>
            <div className="sidebar-grid-side-column ion-padding">
              <div className="sticky-header ion-hide-xl-up">
                <h3 className="flex-title">
                  <FontAwesomeIcon icon={['fal', 'map-marker-alt']} style={{marginLeft: '.8em', marginRight: '.8em'}}/>
                  <div className="flex-grow">
                    {site.name}
                    {site.status ? <FontAwesomeIcon className="color-danger" icon={['fad', 'exclamation-triangle']} style={{marginTop: '.2em', marginLeft: '1.5em'}}/> : null}
                  </div>
                  <span role="button" onClick={toggleSidebar} style={{paddingLeft: '.8em', paddingRight: '.8em'}}>
                    <FontAwesomeIcon icon={['fas', 'times']}/>
                  </span>
                </h3>
              </div>
              {site.cameras.length > 0 ? (
                <IonGrid>
                    {site.cameras.map((camera: any, cKey: string) => (
                      <div key={cKey}>
                        <h5>{camera.name}</h5>
                        <Snapshots name={`sensor-${camera.id}`} snapshots={camera.snapshots}/>
                      </div>
                    ))}
                </IonGrid>
              ) : null}
              {Object.keys(site.externalLinks).length  ? (
                <div>
                  <h5>Site Links</h5>
                  {Object.values(site.externalLinks).map((link: any) => (
                    <a key={link.url} href={link.url} target="_blank" className="contact-detail">
                      <span>{link.label}</span>
                    </a>
                  ))}
                </div>
              ) : null}
              {site.address || site.city || site.phone || site.units ? (
                <div>
                  <h5>Site Details</h5>
                  {site.address ? (
                    <div className="contact-detail">
                      <span className="color-muted">Address</span>
                      <span>{site.address}</span>
                    </div>
                  ) : null}
                  {site.city ? (
                    <div className="contact-detail">
                      <span className="color-muted">City</span>
                      <span>{site.city}</span>
                    </div>
                  ) : null}
                  {site.phone ? (
                    <div className="contact-detail">
                      <span className="color-muted">Phone</span>
                      <span>{site.phone}</span>
                    </div>
                  ) : null}
                  {site.units ? (
                    site.units.map((serialNumber: any, index: any) => (
                      <div className="contact-detail" key={index}>
                        <span className="color-muted">Unit</span>
                        <span>{serialNumber}</span>
                      </div>
                    ))
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </IonContent>
      ) : (
        <IonContent>
          <div className="loader">
            <FontAwesomeIcon className="color-primary" icon={['fad', 'spinner-third']} size="3x" spin/>
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};

export default Site;
