import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar
} from '@ionic/react';

import ChangePasswordForm from '../components/ChangePasswordForm';

const ChangePassword: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings"/>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton color="primary" routerLink="/notifications">
              <FontAwesomeIcon icon={['fad', 'bell']}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding" fullscreen>
        <ChangePasswordForm/>
      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
