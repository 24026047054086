import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  useIonAlert,
} from '@ionic/react';
import {useContext} from 'react';
import {useHistory} from 'react-router';

import './SensorCard.css';
import {AppContext, getAuthToken, getEnvironment} from '../State';
import {sitecontrollerGlobals} from '../variables';

const SensorCard: React.FC<{sensor: any, site: any}> = (props: any) => {
  const {state} = useContext(AppContext);
  const history = useHistory();

  const {sensor, site} = props;

  const authToken = getAuthToken(state);
  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

  const [present] = useIonAlert();

  const executePayloadAction = (action: any) => {
    const executor = () => {
      fetch(`${environmentOptions.url}/api/action/${sensor.id}/${action.key}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      });
    };

    present({
      header: action.label,
      message: 'Are you sure?',
      buttons: [
        {text: 'Cancel'},
        {text: 'Execute', handler: () => executor()},
      ],
    });
  };

  const renderInfoBadge = () => {
    if (sensor.badge) {
      return <IonBadge color="light">{sensor.badge.text}</IonBadge>
    }

    return null;
  };

  return (
    <div className="sensor-card-container">
      <IonCard onClick={() => history.push(`/site/${site.id}/sensor/${sensor.id}`)}>
        <IonCardContent>
          <div className="sensor-card-title">
            <img src={`${environmentOptions.url}/build/icons/${sensor.icon}`} style={{height: '2em'}} alt="Sensor status"/>
            <div className="sensor-card-title-badges">
              {renderInfoBadge()}
              <IonBadge color={sensor.statusColor[0]}>{sensor.statusAbbr}</IonBadge>
            </div>
          </div>
          <div className="sensor-card-ding-row">
            <div className="sensor-card-details">
              <div><strong>{sensor.name}</strong></div>
              <div><em>{sensor.category}</em></div>
              {sensor.description ? <div><small>{sensor.description}</small></div> : null}
            </div>
            {sensor.payloadActions.length > 0 ? (
              <div className="sensor-card-button-container">
                {sensor.payloadActions.map((action: any, index: string) => (
                  <IonButton
                    key={index}
                    size="small"
                    color="light"
                    disabled={action.disabled}
                    onClick={event => {
                      event.stopPropagation();
                      executePayloadAction(action);
                    }}
                  >
                    {action.label}
                  </IonButton>
                ))}
              </div>
            ) : null}
          </div>
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default SensorCard;
