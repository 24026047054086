import {IonApp, setupIonicReact} from '@ionic/react';
import React from 'react';

import Router from './Router';
import {AppContextProvider} from './State';

/* Third party dependencies */
import '@fancyapps/ui/dist/fancybox.esm';

import './declarations.d.ts';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import '@fancyapps/ui/dist/fancybox.css';

import './theme/global.css';
import './theme/variables.css';

import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fad} from '@fortawesome/pro-duotone-svg-icons';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';

library.add(fab, fad, fal, far, fas);

setupIonicReact({
  mode: 'ios',
});

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonApp>
        <Router/>
      </IonApp>
    </AppContextProvider>
  );
};

export default App;
