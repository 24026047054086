import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import {useContext, useEffect, useState} from 'react';

import NotificationsButton from '../components/NotificationsButton';
import SiteCard from '../components/SiteCard';
import { AppContext, getAuthToken, getEnvironment } from '../State';
import {sitecontrollerGlobals} from '../variables';
import autoFetcher from '../services/Autofetcher';

const AllSites: React.FC = () => {
  const {state} = useContext(AppContext);

  const [allSites, setAllSites] = useState([]);
  const [sites, setSites] = useState([]);

  const authToken = getAuthToken(state);
  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

  useEffect(() => {
    let isMounted = true;

    const fetchSites = () => {
      return fetch(`${environmentOptions.url}/api/sites/all`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      })
        .then(response => response.json())
        .then(response => {
          if (!isMounted) {
            return false;
          }

          setAllSites(response);
          setSites(response);
        })
      ;
    };

    fetchSites();

    autoFetcher.addFetcher('all_sites', fetchSites);

    return () => {
      isMounted = false;

      autoFetcher.removeFetcher('all_sites');
    };
  }, [authToken]);

  const handleSearch = (event: any) => {
    setSites(allSites.filter((site: any) => {
      return site.name.toLowerCase().includes(event.detail.value.toLowerCase());
    }));
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
          <IonTitle>All sites</IonTitle>
          <IonButtons slot="end">
            <NotificationsButton/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {allSites.length ? (
        <IonContent className="ion-padding" fullscreen>
          <IonRefresher
            slot="fixed"
            onIonRefresh={event => autoFetcher.fetch().then(() => event.detail.complete())}
            pullMin={100}
            pullMax={100}
          >
            <IonRefresherContent refreshingSpinner="crescent"/>
          </IonRefresher>

          <IonSearchbar className="ion-no-padding" onIonInput={(ev) => handleSearch(ev)}></IonSearchbar>

          <IonGrid>
            <IonRow className="site-card-row">
              {sites.map((site, index) => (
                <IonCol key={index} size="12" sizeSm="6" sizeMd="4" sizeXl="3" style={{padding: 0}}>
                  <SiteCard site={site}/>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </IonContent>
      ) : (
        <IonContent>
          <div className="loader">
            <FontAwesomeIcon className="color-primary" icon={['fad', 'spinner-third']} size="3x" spin/>
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};

export default AllSites;
