import {
  IonCheckbox,
  IonItem,
  IonLabel,
  useIonAlert,
} from '@ionic/react';
import {useContext, useState} from 'react';

import { AppContext, getAuthToken, getEnvironment, getUser, updateUser } from '../State';
import {sitecontrollerGlobals} from '../variables';

const NotificationsForm: React.FC = () => {
  const {dispatch, state} = useContext(AppContext);

  const authToken = getAuthToken(state);
  const user = getUser(state);
  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

  const [emailNotifications, setEmailNotifications] = useState(user.settings.emailNotifications);
  const [handlingForm, setHandlingForm] = useState(false);

  const [present] = useIonAlert();

  const handleSubmit = (data: any) => {
    setHandlingForm(true);

    const formData = {
      emailNotifications,
      ...data,
    };

    setEmailNotifications(formData.emailNotifications);

    fetch(`${environmentOptions.url}/api/account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(response => {
        if (!response.message) {
          dispatch(updateUser(response.user));

          return;
        }

        present({
          header: 'Failed to save settings',
          message: response.message,
          buttons: [
            {text: 'Dismiss'},
          ],
        });

        setHandlingForm(false);
      })
      .catch(() => {
        setHandlingForm(false);
      })
    ;
  };

  return (
    <div>
      <IonItem>
        <IonLabel>Email notifications</IonLabel>
        <IonCheckbox checked={emailNotifications} onIonChange={e => {handleSubmit({emailNotifications: e.detail.checked})}} />
      </IonItem>
    </div>
  );
};

export default NotificationsForm;
