import { Network } from '@capacitor/network';
import {ActionPerformed, PushNotifications, PushNotificationSchema} from '@capacitor/push-notifications';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IonRouterOutlet, IonSplitPane, isPlatform, useIonToast} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {Route} from 'react-router-dom';

import Menu from './components/Menu';
import AllSites from './pages/AllSites';
import Announcements from './pages/Announcements';
import AnnouncementModals from './components/AnnouncementModals';
import ChangePassword from './pages/ChangePassword';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotificationSettings from './pages/NotificationSettings';
import Sensor from './pages/Sensor';
import Settings from './pages/Settings';
import Site from './pages/Site';
import {
  AppContext,
  getAuthToken,
  getEnvironment,
  loggedIn,
  logout, setAnnouncements,
  setNotifications,
  setSites,
  setState
} from './State';
import autoFetcher from './services/Autofetcher';
import {fillStorage, retrieveStorage} from './Storage';
import {sitecontrollerGlobals} from './variables';

const Router: React.FC = () => {
  const {dispatch, state} = useContext(AppContext);

  const authToken = getAuthToken(state);
  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];
  const [webPushSubscription, setWebPushSubscription] = useState(null);

  const [present, dismiss] = useIonToast();
  const [presentAlert, dismissAlert] = useIonToast();

  const history = useHistory();

  const [connectedState, setConnectedState] = useState(true);

  useEffect(() => {
    autoFetcher.start();

    return () => {
      autoFetcher.stop();
    };
  }, []);

  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      setConnectedState(status.connected);
    });
  }, []);

  useEffect(() => {
    if (!connectedState) {
      return;
    }

    retrieveStorage().then(state => {
      if (state && state.auth && state.auth.token) {
        dispatch(setState(state));

        const authEnvironmentOptions = (sitecontrollerGlobals.environments as any)[state.auth.environment ?? 'prod'];

        fetch(`${authEnvironmentOptions.url}/api/account`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${state.auth.token}`,
          },
        })
          .then(response => {
            if (response.status >= 400) {
              return;
            }

            return response.json();
          })
          .then(response => {
            dispatch(loggedIn(state.auth.token, response.user, state.auth.environment));
          });
      }
    });

    const fetchInfo = () => {
      dismissAlert();

      if (null === authToken) {
        dispatch(setAnnouncements([]));
        dispatch(setNotifications([]));
        dispatch(setSites([]));

        return;
      }

      return fetch(`${environmentOptions.url}/api/info`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      })
        .then(response => {
          if (response.status === 401) {
            dispatch(logout());

            fillStorage({});

            throw new Error();
          }

          return response.json();
        })
        .then(response => {
          dispatch(setAnnouncements(response.announcements));
          dispatch(setNotifications(response.notifications));
          dispatch(setSites(response.sites));
        })
        .catch(() => {
          presentAlert({
            header: 'Unable to fetch data',
            color: 'warning',
          });
        })
      ;
    };

    fetchInfo();

    autoFetcher.addFetcher('info', fetchInfo);

    return () => {
      autoFetcher.removeFetcher('info');
    };
  }, [authToken, connectedState, dispatch]);

  useEffect(() => {
    if (authToken) {
      fillStorage(state);
    }
  }, [authToken, state]);

  useEffect(() => {
    if (!authToken || !isPlatform('hybrid')) {
      return;
    }

    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      }
    });

    PushNotifications.addListener('registration', token => {
      (global as any).pushNotificationToken = token.value;

      fetch(`${environmentOptions.url}/api/push/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          token: token.value,
          platform: isPlatform('ios') ? 'ios' : 'android',
        }),
      });
    });

    PushNotifications.addListener('registrationError', error => {
      console.log('registered error');
      console.log(error);
    });
  }, [authToken]);

  useEffect(() => {
    if (!authToken || !isPlatform('hybrid')) {
      return;
    }

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.log(notification);

      present({
        header: notification.title,
        message: notification.body,
        duration: 5000,
        buttons: [
          {
            text: 'View',
            handler: () => {
              dismiss();

              history.push(notification.data.url);
            }
          },
        ],
        // onDidDismiss: () => console.log('dismissed'),
        // onWillDismiss: () => console.log('will dismiss'),
      });
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
      console.log('Push action performed on');
      console.log(action);

      history.push(action.notification.data.url);
    });

    return () => {
      PushNotifications.removeAllListeners();
    };
  }, [authToken, history]);

  useEffect(() => {
    if (!authToken || isPlatform('hybrid') || !webPushSubscription) {
      return;
    }

    fetch(`${environmentOptions.url}/api/push/register`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        subscription: webPushSubscription,
        platform: 'web',
      }),
    });
  }, [authToken, webPushSubscription]);

  useEffect(() => {
    let i = 0;

    const webPushInterval = setInterval(() => {
      const originalWebPushSubscription = (global as any).webPushSubscription;

      if (originalWebPushSubscription) {
        setWebPushSubscription(originalWebPushSubscription);

        clearInterval(webPushInterval);
      }

      if (i > 100) {
        clearInterval(webPushInterval);
      }

      ++i;
    }, 5000);
  }, []);

  return (
    <IonReactRouter>
      <AnnouncementModals/>
      {null === authToken ? <Route path="/" component={Login}/> : (
        <IonSplitPane contentId="main">
          <Menu/>
          <IonRouterOutlet id="main">
            <Route path="/" exact={true} component={Dashboard}/>
            <Route path="/announcements" exact={true} component={Announcements}/>
            <Route path="/sites/all" exact={true} component={AllSites}/>
            <Route path="/site/:siteId" exact={true} component={Site}/>
            <Route path="/site/:siteId/sensor/:sensorId" exact={true} component={Sensor}/>
            <Route path="/settings" exact={true} component={Settings}/>
            <Route path="/settings/changePassword" exact={true} component={ChangePassword}/>
            <Route path="/settings/notifications" exact={true} component={NotificationSettings}/>
          </IonRouterOutlet>
          {!connectedState ? (
            <div className="offline-overlay">
              <div className="message">
                <div>
                  <FontAwesomeIcon icon="wifi-slash" size="3x" fixedWidth={true}/>
                </div>
                No connection
              </div>
            </div>
          ) : null}
        </IonSplitPane>
      )}
    </IonReactRouter>
  );
};

export default Router;
