import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {useContext, useState} from 'react';

import NotificationsButton from '../components/NotificationsButton';
import SiteCard from '../components/SiteCard';
import autoFetcher from '../services/Autofetcher';
import {AppContext, getSites} from '../State';

const Dashboard: React.FC = () => {
  const {state} = useContext(AppContext);

  const allSites: any[] = getSites(state);
  const [sites, setSites] = useState([...allSites]);

  const handleSearch = (event: any) => {
    setSites(allSites.filter((site: any) => {
      return site.name.toLowerCase().includes(event.detail.value.toLowerCase());
    }));
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
          <IonTitle>My sites</IonTitle>
          <IonButtons slot="end">
            <NotificationsButton/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding" fullscreen>
        <IonRefresher
          slot="fixed"
          onIonRefresh={event => autoFetcher.fetch().then(() => event.detail.complete())}
          pullMin={100}
          pullMax={100}
        >
          <IonRefresherContent refreshingSpinner="crescent"/>
        </IonRefresher>

        <IonSearchbar className="ion-no-padding" onIonInput={(ev) => handleSearch(ev)}></IonSearchbar>


        <IonGrid>
          <IonRow className="site-card-row">
            {sites.map((site, index) => (
              <IonCol key={index} size="12" sizeSm="6" sizeMd="4" sizeXl="3" style={{padding: 0}}>
                <SiteCard site={site}/>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
